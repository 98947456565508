@import 'normalize.css';

@font-face {
  font-family: 'Circular Pro';
  src: url('./fonts/CircularPro-Black.woff') format('woff');
  /* font-weight: 200; */
  font-style: bold;
}

@font-face {
  font-family: 'Circular Pro';
  src: url('./fonts/CircularPro-BlackItalic.woff') format('woff');
  /* font-weight: 200; */
  font-style: italic;
}

@font-face {
  font-family: 'Circular Pro';
  src: url('./fonts/CircularPro-Bold.woff') format('woff');
  /* font-weight: 200; */
  font-style: bold;
}

@font-face {
  font-family: 'Circular Pro';
  src: url('./fonts/CircularPro-BoldItalic.woff') format('woff');
  /* font-weight: 200; */
  font-style: italic;
}

@font-face {
  font-family: 'Circular Pro';
  src: url('./fonts/CircularPro-Medium.woff') format('woff');
  /* font-weight: 200; */
  font-style: normal;
}

@font-face {
  font-family: 'Circular Pro';
  src: url('./fonts/CircularPro-MediumItalic.woff') format('woff');
  /* font-weight: 200; */
  font-style: italic;
}

@font-face {
  font-family: 'Circular Pro';
  src: url('./fonts/CircularPro-Book.woff') format('woff');
  /* font-weight: 200; */
  font-style: normal;
}

@font-face {
  font-family: 'Circular Pro';
  src: url('./fonts/CircularPro-BookItalic.woff') format('woff');
  /* font-weight: 200; */
  font-style: italic;
}


body {
  margin: 0;
  font-family: "Circular Pro";
}

.customthick {
  font-weight: 900;

}

.customBoldSoft {
  font-weight: 700;
}