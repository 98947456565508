
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

.ui.form textarea,
.ui.form input[type="checkbox"] {
  vertical-align: top;
}

/*******************************
         Element Types
*******************************/

/*--------------------
     Required Field
---------------------*/

.ui.form .required.fields:not(.grouped) > .field > label:after,
.ui.form .required.fields.grouped > label:after,
.ui.form .required.field > label:after,
.ui.form .required.fields:not(.grouped) > .field > .checkbox:after,
.ui.form .required.field > .checkbox:after {
  margin: -0.2em 0em 0em 0.2em;
  content: '*';
  color: #DB2828;
}

.ui.form .required.fields:not(.grouped) > .field > label:after,
.ui.form .required.fields.grouped > label:after,
.ui.form .required.field > label:after {
  display: inline-block;
  vertical-align: top;
}

.ui.form .required.fields:not(.grouped) > .field > .checkbox:after,
.ui.form .required.field > .checkbox:after {
  position: absolute;
  top: 0%;
  left: 100%;
}

/*******************************
           Variations
*******************************/

/*--------------------
    Inverted Colors
---------------------*/

.ui.inverted.form label,
.ui.form .inverted.segment label,
.ui.form .inverted.segment .ui.checkbox label,
.ui.form .inverted.segment .ui.checkbox .box,
.ui.inverted.form .ui.checkbox label,
.ui.inverted.form .ui.checkbox .box,
.ui.inverted.form .inline.fields > label,
.ui.inverted.form .inline.fields .field > label,
.ui.inverted.form .inline.fields .field > p,
.ui.inverted.form .inline.field > label,
.ui.inverted.form .inline.field > p {
  color: rgba(255, 255, 255, 0.9);
}

/*******************************
           Checkbox
*******************************/

/*--------------
    Content
---------------*/

.ui.checkbox {
  position: relative;
  display: inline-block;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  outline: none;
  vertical-align: baseline;
  font-style: normal;
  min-height: 17px;
  font-size: 1rem;
  line-height: 17px;
  min-width: 17px;
}

/* HTML Checkbox */

.ui.checkbox input[type="checkbox"],
.ui.checkbox input[type="radio"] {
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0 !important;
  outline: none;
  z-index: 3;
  width: 17px;
  height: 17px;
}

/*--------------
      Box
---------------*/

.ui.checkbox .box,
.ui.checkbox label {
  cursor: auto;
  position: relative;
  display: block;
  padding-left: 1.85714em;
  outline: none;
  font-size: 1em;
}

.ui.checkbox .box:before,
.ui.checkbox label:before {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 17px;
  height: 17px;
  content: '';
  background: #FFFFFF;
  border-radius: 0.21428571rem;
  transition: border 0.1s ease, opacity 0.1s ease, transform 0.1s ease, box-shadow 0.1s ease;
  border: 1px solid #D4D4D5;
}

/*--------------
    Checkmark
---------------*/

.ui.checkbox .box:after,
.ui.checkbox label:after {
  position: absolute;
  font-size: 14px;
  top: 0px;
  left: 0px;
  width: 17px;
  height: 17px;
  text-align: center;
  opacity: 0;
  color: rgba(0, 0, 0, 0.87);
  transition: border 0.1s ease, opacity 0.1s ease, transform 0.1s ease, box-shadow 0.1s ease;
}

/*--------------
      Label
---------------*/

/* Inside */

.ui.checkbox label,
.ui.checkbox + label {
  color: rgba(0, 0, 0, 0.87);
  transition: color 0.1s ease;
}

/* Outside */

.ui.checkbox + label {
  vertical-align: middle;
}

/*******************************
           States
*******************************/

/*--------------
      Hover
---------------*/

.ui.checkbox .box:hover::before,
.ui.checkbox label:hover::before {
  background: #FFFFFF;
  border-color: rgba(34, 36, 38, 0.35);
}

.ui.checkbox label:hover,
.ui.checkbox + label:hover {
  color: rgba(0, 0, 0, 0.8);
}

/*--------------
      Down
---------------*/

.ui.checkbox .box:active::before,
.ui.checkbox label:active::before {
  background: #F9FAFB;
  border-color: rgba(34, 36, 38, 0.35);
}

.ui.checkbox .box:active::after,
.ui.checkbox label:active::after {
  color: rgba(0, 0, 0, 0.95);
}

.ui.checkbox input:active ~ label {
  color: rgba(0, 0, 0, 0.95);
}

/*--------------
     Focus
---------------*/

.ui.checkbox input:focus ~ .box:before,
.ui.checkbox input:focus ~ label:before {
  background: #FFFFFF;
  border-color: #96C8DA;
}

.ui.checkbox input:focus ~ .box:after,
.ui.checkbox input:focus ~ label:after {
  color: rgba(0, 0, 0, 0.95);
}

.ui.checkbox input:focus ~ label {
  color: rgba(0, 0, 0, 0.95);
}

/*--------------
     Active
---------------*/

.ui.checkbox input:checked ~ .box:before,
.ui.checkbox input:checked ~ label:before {
  background: #FFFFFF;
  border-color: rgba(34, 36, 38, 0.35);
}

.ui.checkbox input:checked ~ .box:after,
.ui.checkbox input:checked ~ label:after {
  opacity: 1;
  color: rgba(0, 0, 0, 0.95);
}

/*--------------
  Indeterminate
---------------*/

.ui.checkbox input:not([type=radio]):indeterminate ~ .box:before,
.ui.checkbox input:not([type=radio]):indeterminate ~ label:before {
  background: #FFFFFF;
  border-color: rgba(34, 36, 38, 0.35);
}

.ui.checkbox input:not([type=radio]):indeterminate ~ .box:after,
.ui.checkbox input:not([type=radio]):indeterminate ~ label:after {
  opacity: 1;
  color: rgba(0, 0, 0, 0.95);
}

/*--------------
  Active Focus
---------------*/

.ui.checkbox input:not([type=radio]):indeterminate:focus ~ .box:before,
.ui.checkbox input:not([type=radio]):indeterminate:focus ~ label:before,
.ui.checkbox input:checked:focus ~ .box:before,
.ui.checkbox input:checked:focus ~ label:before {
  background: #FFFFFF;
  border-color: #96C8DA;
}

.ui.checkbox input:not([type=radio]):indeterminate:focus ~ .box:after,
.ui.checkbox input:not([type=radio]):indeterminate:focus ~ label:after,
.ui.checkbox input:checked:focus ~ .box:after,
.ui.checkbox input:checked:focus ~ label:after {
  color: rgba(0, 0, 0, 0.95);
}

/*--------------
    Read-Only
---------------*/

.ui.read-only.checkbox,
.ui.read-only.checkbox label {
  cursor: default;
}

/*--------------
     Disabled
---------------*/

.ui.disabled.checkbox .box:after,
.ui.disabled.checkbox label,
.ui.checkbox input[disabled] ~ .box:after,
.ui.checkbox input[disabled] ~ label {
  cursor: default !important;
  opacity: 0.5;
  color: #000000;
}

/*--------------
     Hidden
---------------*/

/* Initialized checkbox moves input below element
 to prevent manually triggering */

.ui.checkbox input.hidden {
  z-index: -1;
}

/* Selectable Label */

.ui.checkbox input.hidden + label {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*******************************
           Checkbox
*******************************/

/*--------------
    Content
---------------*/

.ui.checkbox {
  position: relative;
  display: inline-block;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  outline: none;
  vertical-align: baseline;
  font-style: normal;
  min-height: 17px;
  font-size: 1rem;
  line-height: 17px;
  min-width: 17px;
}

/* HTML Checkbox */

.ui.checkbox input[type="checkbox"],
.ui.checkbox input[type="radio"] {
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0 !important;
  outline: none;
  z-index: 3;
  width: 17px;
  height: 17px;
}

/*--------------
      Box
---------------*/

.ui.checkbox .box,
.ui.checkbox label {
  cursor: auto;
  position: relative;
  display: block;
  padding-left: 1.85714em;
  outline: none;
  font-size: 1em;
}

.ui.checkbox .box:before,
.ui.checkbox label:before {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 17px;
  height: 17px;
  content: '';
  background: #FFFFFF;
  border-radius: 0.21428571rem;
  transition: border 0.1s ease, opacity 0.1s ease, transform 0.1s ease, box-shadow 0.1s ease;
  border: 1px solid #D4D4D5;
}

/*--------------
    Checkmark
---------------*/

.ui.checkbox .box:after,
.ui.checkbox label:after {
  position: absolute;
  font-size: 14px;
  top: 0px;
  left: 0px;
  width: 17px;
  height: 17px;
  text-align: center;
  opacity: 0;
  color: rgba(0, 0, 0, 0.87);
  transition: border 0.1s ease, opacity 0.1s ease, transform 0.1s ease, box-shadow 0.1s ease;
}

/*--------------
      Label
---------------*/

/* Inside */

.ui.checkbox label,
.ui.checkbox + label {
  color: rgba(0, 0, 0, 0.87);
  transition: color 0.1s ease;
}

/* Outside */

.ui.checkbox + label {
  vertical-align: middle;
}

/*******************************
           States
*******************************/

/*--------------
      Hover
---------------*/

.ui.checkbox .box:hover::before,
.ui.checkbox label:hover::before {
  background: #FFFFFF;
  border-color: rgba(34, 36, 38, 0.35);
}

.ui.checkbox label:hover,
.ui.checkbox + label:hover {
  color: rgba(0, 0, 0, 0.8);
}

/*--------------
      Down
---------------*/

.ui.checkbox .box:active::before,
.ui.checkbox label:active::before {
  background: #F9FAFB;
  border-color: rgba(34, 36, 38, 0.35);
}

.ui.checkbox .box:active::after,
.ui.checkbox label:active::after {
  color: rgba(0, 0, 0, 0.95);
}

.ui.checkbox input:active ~ label {
  color: rgba(0, 0, 0, 0.95);
}

/*--------------
     Focus
---------------*/

.ui.checkbox input:focus ~ .box:before,
.ui.checkbox input:focus ~ label:before {
  background: #FFFFFF;
  border-color: #96C8DA;
}

.ui.checkbox input:focus ~ .box:after,
.ui.checkbox input:focus ~ label:after {
  color: rgba(0, 0, 0, 0.95);
}

.ui.checkbox input:focus ~ label {
  color: rgba(0, 0, 0, 0.95);
}

/*--------------
     Active
---------------*/

.ui.checkbox input:checked ~ .box:before,
.ui.checkbox input:checked ~ label:before {
  background: #FFFFFF;
  border-color: rgba(34, 36, 38, 0.35);
}

.ui.checkbox input:checked ~ .box:after,
.ui.checkbox input:checked ~ label:after {
  opacity: 1;
  color: rgba(0, 0, 0, 0.95);
}

/*--------------
  Indeterminate
---------------*/

.ui.checkbox input:not([type=radio]):indeterminate ~ .box:before,
.ui.checkbox input:not([type=radio]):indeterminate ~ label:before {
  background: #FFFFFF;
  border-color: rgba(34, 36, 38, 0.35);
}

.ui.checkbox input:not([type=radio]):indeterminate ~ .box:after,
.ui.checkbox input:not([type=radio]):indeterminate ~ label:after {
  opacity: 1;
  color: rgba(0, 0, 0, 0.95);
}

/*--------------
  Active Focus
---------------*/

.ui.checkbox input:not([type=radio]):indeterminate:focus ~ .box:before,
.ui.checkbox input:not([type=radio]):indeterminate:focus ~ label:before,
.ui.checkbox input:checked:focus ~ .box:before,
.ui.checkbox input:checked:focus ~ label:before {
  background: #FFFFFF;
  border-color: #96C8DA;
}

.ui.checkbox input:not([type=radio]):indeterminate:focus ~ .box:after,
.ui.checkbox input:not([type=radio]):indeterminate:focus ~ label:after,
.ui.checkbox input:checked:focus ~ .box:after,
.ui.checkbox input:checked:focus ~ label:after {
  color: rgba(0, 0, 0, 0.95);
}

/*--------------
    Read-Only
---------------*/

.ui.read-only.checkbox,
.ui.read-only.checkbox label {
  cursor: default;
}

/*--------------
     Disabled
---------------*/

.ui.disabled.checkbox .box:after,
.ui.disabled.checkbox label,
.ui.checkbox input[disabled] ~ .box:after,
.ui.checkbox input[disabled] ~ label {
  cursor: default !important;
  opacity: 0.5;
  color: #000000;
}

/*--------------
     Hidden
---------------*/

/* Initialized checkbox moves input below element
 to prevent manually triggering */

.ui.checkbox input.hidden {
  z-index: -1;
}

/* Selectable Label */

.ui.checkbox input.hidden + label {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*******************************
             Types
*******************************/

/*--------------
     Radio
---------------*/

.ui.radio.checkbox {
  min-height: 15px;
}

.ui.radio.checkbox .box,
.ui.radio.checkbox label {
  padding-left: 1.85714em;
}

/* Box */

.ui.radio.checkbox .box:before,
.ui.radio.checkbox label:before {
  content: '';
  transform: none;
  width: 15px;
  height: 15px;
  border-radius: 500rem;
  top: 1px;
  left: 0px;
}

/* Bullet */

.ui.radio.checkbox .box:after,
.ui.radio.checkbox label:after {
  border: none;
  content: '' !important;
  width: 15px;
  height: 15px;
  line-height: 15px;
}

/* Radio Checkbox */

.ui.radio.checkbox .box:after,
.ui.radio.checkbox label:after {
  top: 1px;
  left: 0px;
  width: 15px;
  height: 15px;
  border-radius: 500rem;
  transform: scale(0.46666667);
  background-color: rgba(0, 0, 0, 0.87);
}

/* Focus */

.ui.radio.checkbox input:focus ~ .box:before,
.ui.radio.checkbox input:focus ~ label:before {
  background-color: #FFFFFF;
}

.ui.radio.checkbox input:focus ~ .box:after,
.ui.radio.checkbox input:focus ~ label:after {
  background-color: rgba(0, 0, 0, 0.95);
}

/* Indeterminate */

.ui.radio.checkbox input:indeterminate ~ .box:after,
.ui.radio.checkbox input:indeterminate ~ label:after {
  opacity: 0;
}

/* Active */

.ui.radio.checkbox input:checked ~ .box:before,
.ui.radio.checkbox input:checked ~ label:before {
  background-color: #FFFFFF;
}

.ui.radio.checkbox input:checked ~ .box:after,
.ui.radio.checkbox input:checked ~ label:after {
  background-color: rgba(0, 0, 0, 0.95);
}

/* Active Focus */

.ui.radio.checkbox input:focus:checked ~ .box:before,
.ui.radio.checkbox input:focus:checked ~ label:before {
  background-color: #FFFFFF;
}

.ui.radio.checkbox input:focus:checked ~ .box:after,
.ui.radio.checkbox input:focus:checked ~ label:after {
  background-color: rgba(0, 0, 0, 0.95);
}

/*--------------
     Slider
---------------*/

.ui.slider.checkbox {
  min-height: 1.25rem;
}

/* Input */

.ui.slider.checkbox input {
  width: 3.5rem;
  height: 1.25rem;
}

/* Label */

.ui.slider.checkbox .box,
.ui.slider.checkbox label {
  padding-left: 4.5rem;
  line-height: 1rem;
  color: rgba(0, 0, 0, 0.4);
}

/* Line */

.ui.slider.checkbox .box:before,
.ui.slider.checkbox label:before {
  display: block;
  position: absolute;
  content: '';
  border: none !important;
  left: 0em;
  z-index: 1;
  top: 0.4rem;
  background-color: rgba(0, 0, 0, 0.05);
  width: 3.5rem;
  height: 0.21428571rem;
  transform: none;
  border-radius: 500rem;
  transition: background 0.3s ease;
}

/* Handle */

.ui.slider.checkbox .box:after,
.ui.slider.checkbox label:after {
  background: #FFFFFF linear-gradient(transparent, rgba(0, 0, 0, 0.05));
  position: absolute;
  content: '' !important;
  opacity: 1;
  z-index: 2;
  border: none;
  box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15), 0px 0px 0px 1px rgba(34, 36, 38, 0.15) inset;
  width: 1.5rem;
  height: 1.5rem;
  top: -0.25rem;
  left: 0em;
  transform: none;
  border-radius: 500rem;
  transition: left 0.3s ease;
}

/* Focus */

.ui.slider.checkbox input:focus ~ .box:before,
.ui.slider.checkbox input:focus ~ label:before {
  background-color: rgba(0, 0, 0, 0.15);
  border: none;
}

/* Hover */

.ui.slider.checkbox .box:hover,
.ui.slider.checkbox label:hover {
  color: rgba(0, 0, 0, 0.8);
}

.ui.slider.checkbox .box:hover::before,
.ui.slider.checkbox label:hover::before {
  background: rgba(0, 0, 0, 0.15);
}

/* Active */

.ui.slider.checkbox input:checked ~ .box,
.ui.slider.checkbox input:checked ~ label {
  color: rgba(0, 0, 0, 0.95) !important;
}

.ui.slider.checkbox input:checked ~ .box:before,
.ui.slider.checkbox input:checked ~ label:before {
  background-color: #545454 !important;
}

.ui.slider.checkbox input:checked ~ .box:after,
.ui.slider.checkbox input:checked ~ label:after {
  left: 2rem;
}

/* Active Focus */

.ui.slider.checkbox input:focus:checked ~ .box,
.ui.slider.checkbox input:focus:checked ~ label {
  color: rgba(0, 0, 0, 0.95) !important;
}

.ui.slider.checkbox input:focus:checked ~ .box:before,
.ui.slider.checkbox input:focus:checked ~ label:before {
  background-color: #000000 !important;
}

/*--------------
     Toggle
---------------*/

.ui.toggle.checkbox {
  min-height: 1.5rem;
}

/* Input */

.ui.toggle.checkbox input {
  width: 3.5rem;
  height: 1.5rem;
}

/* Label */

.ui.toggle.checkbox .box,
.ui.toggle.checkbox label {
  min-height: 1.5rem;
  padding-left: 4.5rem;
  color: rgba(0, 0, 0, 0.87);
}

.ui.toggle.checkbox label {
  padding-top: 0.15em;
}

/* Switch */

.ui.toggle.checkbox .box:before,
.ui.toggle.checkbox label:before {
  display: block;
  position: absolute;
  content: '';
  z-index: 1;
  transform: none;
  border: none;
  top: 0rem;
  background: rgba(0, 0, 0, 0.05);
  box-shadow: none;
  width: 3rem; /*width of line*/
  height: 1.5rem;
  border-radius: 500rem;
}

/* Handle */

.ui.toggle.checkbox .box:after,
.ui.toggle.checkbox label:after {
  background: #FFFFFF linear-gradient(transparent, rgba(0, 0, 0, 0.05));
  position: absolute;
  content: '' !important;
  opacity: 1;
  z-index: 2;
  border: none;
  box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15), 0px 0px 0px 1px rgba(34, 36, 38, 0.15) inset;
  width: 1.3rem;
  height: 1.3rem;
  top: 0.09rem;
  left: 0em;
  border-radius: 500rem;
  transition: background 0.3s ease, left 0.3s ease;
}

.ui.toggle.checkbox input ~ .box:after,
.ui.toggle.checkbox input ~ label:after {
  left: 0.09rem;
  box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15), 0px 0px 0px 1px rgba(34, 36, 38, 0.15) inset;
}

/* Focus */

.ui.toggle.checkbox input:focus ~ .box:before,
.ui.toggle.checkbox input:focus ~ label:before {
  background-color: rgba(0, 0, 0, 0.15);
  border: none;
}

/* Hover */

.ui.toggle.checkbox .box:hover::before,
.ui.toggle.checkbox label:hover::before {
  background-color: rgba(0, 0, 0, 0.15);
  border: none;
}

/* Active */

.ui.toggle.checkbox input:checked ~ .box,
.ui.toggle.checkbox input:checked ~ label {
  color: rgba(0, 0, 0, 0.95) !important;
}

.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
  background-color: #2185D0 !important;
}

.ui.toggle.checkbox input:checked ~ .box:after,
.ui.toggle.checkbox input:checked ~ label:after {
  left: 1.6rem;
  box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15), 0px 0px 0px 1px rgba(34, 36, 38, 0.15) inset;
}

/* Active Focus */

.ui.toggle.checkbox input:focus:checked ~ .box,
.ui.toggle.checkbox input:focus:checked ~ label {
  color: rgba(0, 0, 0, 0.95) !important;
}

.ui.toggle.checkbox input:focus:checked ~ .box:before,
.ui.toggle.checkbox input:focus:checked ~ label:before {
  background-color: #0d71bb !important;
}

/*******************************
            Variations
*******************************/

/*--------------
     Fitted
---------------*/

.ui.fitted.checkbox .box,
.ui.fitted.checkbox label {
  padding-left: 0em !important;
}

.ui.fitted.toggle.checkbox,
.ui.fitted.toggle.checkbox {
  width: 3.5rem;
}

.ui.fitted.slider.checkbox,
.ui.fitted.slider.checkbox {
  width: 3.5rem;
}

/*******************************
         Theme Overrides
*******************************/

@font-face {
  font-family: 'Checkbox';
  src: url(data:application/x-font-ttf;charset=utf-8;base64,AAEAAAALAIAAAwAwT1MvMg8SBD8AAAC8AAAAYGNtYXAYVtCJAAABHAAAAFRnYXNwAAAAEAAAAXAAAAAIZ2x5Zn4huwUAAAF4AAABYGhlYWQGPe1ZAAAC2AAAADZoaGVhB30DyAAAAxAAAAAkaG10eBBKAEUAAAM0AAAAHGxvY2EAmgESAAADUAAAABBtYXhwAAkALwAAA2AAAAAgbmFtZSC8IugAAAOAAAABknBvc3QAAwAAAAAFFAAAACAAAwMTAZAABQAAApkCzAAAAI8CmQLMAAAB6wAzAQkAAAAAAAAAAAAAAAAAAAABEAAAAAAAAAAAAAAAAAAAAABAAADoAgPA/8AAQAPAAEAAAAABAAAAAAAAAAAAAAAgAAAAAAADAAAAAwAAABwAAQADAAAAHAADAAEAAAAcAAQAOAAAAAoACAACAAIAAQAg6AL//f//AAAAAAAg6AD//f//AAH/4xgEAAMAAQAAAAAAAAAAAAAAAQAB//8ADwABAAAAAAAAAAAAAgAANzkBAAAAAAEAAAAAAAAAAAACAAA3OQEAAAAAAQAAAAAAAAAAAAIAADc5AQAAAAABAEUAUQO7AvgAGgAAARQHAQYjIicBJjU0PwE2MzIfAQE2MzIfARYVA7sQ/hQQFhcQ/uMQEE4QFxcQqAF2EBcXEE4QAnMWEP4UEBABHRAXFhBOEBCoAXcQEE4QFwAAAAABAAABbgMlAkkAFAAAARUUBwYjISInJj0BNDc2MyEyFxYVAyUQEBf9SRcQEBAQFwK3FxAQAhJtFxAQEBAXbRcQEBAQFwAAAAABAAAASQMlA24ALAAAARUUBwYrARUUBwYrASInJj0BIyInJj0BNDc2OwE1NDc2OwEyFxYdATMyFxYVAyUQEBfuEBAXbhYQEO4XEBAQEBfuEBAWbhcQEO4XEBACEm0XEBDuFxAQEBAX7hAQF20XEBDuFxAQEBAX7hAQFwAAAQAAAAIAAHRSzT9fDzz1AAsEAAAAAADRsdR3AAAAANGx1HcAAAAAA7sDbgAAAAgAAgAAAAAAAAABAAADwP/AAAAEAAAAAAADuwABAAAAAAAAAAAAAAAAAAAABwQAAAAAAAAAAAAAAAIAAAAEAABFAyUAAAMlAAAAAAAAAAoAFAAeAE4AcgCwAAEAAAAHAC0AAQAAAAAAAgAAAAAAAAAAAAAAAAAAAAAAAAAOAK4AAQAAAAAAAQAIAAAAAQAAAAAAAgAHAGkAAQAAAAAAAwAIADkAAQAAAAAABAAIAH4AAQAAAAAABQALABgAAQAAAAAABgAIAFEAAQAAAAAACgAaAJYAAwABBAkAAQAQAAgAAwABBAkAAgAOAHAAAwABBAkAAwAQAEEAAwABBAkABAAQAIYAAwABBAkABQAWACMAAwABBAkABgAQAFkAAwABBAkACgA0ALBDaGVja2JveABDAGgAZQBjAGsAYgBvAHhWZXJzaW9uIDIuMABWAGUAcgBzAGkAbwBuACAAMgAuADBDaGVja2JveABDAGgAZQBjAGsAYgBvAHhDaGVja2JveABDAGgAZQBjAGsAYgBvAHhSZWd1bGFyAFIAZQBnAHUAbABhAHJDaGVja2JveABDAGgAZQBjAGsAYgBvAHhGb250IGdlbmVyYXRlZCBieSBJY29Nb29uLgBGAG8AbgB0ACAAZwBlAG4AZQByAGEAdABlAGQAIABiAHkAIABJAGMAbwBNAG8AbwBuAC4AAAADAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA) format('truetype');
}

/* Checkmark */

.ui.checkbox label:after,
.ui.checkbox .box:after {
  font-family: 'Checkbox';
}

/* Checked */

.ui.checkbox input:checked ~ .box:after,
.ui.checkbox input:checked ~ label:after {
  content: '\e800';
}

/* Indeterminate */

.ui.checkbox input:indeterminate ~ .box:after,
.ui.checkbox input:indeterminate ~ label:after {
  font-size: 12px;
  content: '\e801';
}

.ui.toggle.checkbox input:checked~.box:before, .ui.toggle.checkbox input:checked~label:before {
  background-color: #2FD5CB !important;
}

.ui.toggle.checkbox input:focus:checked~.box:before, .ui.toggle.checkbox input:focus:checked~label:before {
  background-color: #2FD5CB!important;
}